<template>
  <div class="carousel-wrapper" ref="carouselWrapper">
    <!-- Imagen de fondo -->
    <div class="carousel-background"></div>

    <!-- Contenedor del contenido del carrusel -->
    <div
      class="carousel-products-wrapper"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
    >
      <div class="carousel-products" :style="{ transform: `translateX(-${currentIndex * 100}%)`, transition: transitionActive ? 'transform 0.5s ease-in-out' : 'none' }">
        <!-- Clonamos la última diapositiva al inicio para el efecto infinito -->
        <div class="carousel__item" v-if="carouselImages.length" :key="'last-clone'">
          <div class="carousel-content-with-background">
            <img :src="carouselImages[carouselImages.length - 1].smallImage" alt="Small Image" class="small-image" />
            <p>{{ carouselImages[carouselImages.length - 1].skus.join(', ') }}</p>
            <button class="quote-button" @click="openModal(carouselImages[carouselImages.length - 1])">Contactanos</button>
          </div>
          <div class="carousel-image">
            <img :src="carouselImages[carouselImages.length - 1].image" :alt="carouselImages[carouselImages.length - 1].title" />
          </div>
        </div>

        <!-- Diapositivas reales -->
        <div v-for="(slide, index) in carouselImages" :key="index" class="carousel__item">
          <div class="carousel-content-with-background">
            <img :src="slide.smallImage" alt="Small Image" class="small-image" />
            <p>{{ slide.skus.join(', ') }}</p>
            <a href="/contact">
              <button class="quote-button" >Contactanos</button>
            </a>
          </div>
          <div class="carousel-image">
            <img :src="slide.image" :alt="slide.title" />
          </div>
        </div>

        <!-- Clonamos la primera diapositiva al final para el efecto infinito -->
        <div class="carousel__item" v-if="carouselImages.length" :key="'first-clone'">
          <div class="carousel-content-with-background">
            <img :src="carouselImages[0].smallImage" alt="Small Image" class="small-image" />
            <p>{{ carouselImages[0].skus.join(', ') }}</p>
            <button class="quote-button" @click="openModal(carouselImages[0])">Cotizar ahora</button>
          </div>
          <div class="carousel-image">
            <img :src="carouselImages[0].image" :alt="carouselImages[0].title" />
          </div>
        </div>
      </div>
    </div>

    <!-- Botones de control -->
    <button class="carousel__control prev" @click="prevSlide">&lt;</button>
    <button class="carousel__control next" @click="nextSlide">&gt;</button>

    <!-- Mostrar modal de cotización si está activo -->
    <ProductModal v-if="showModal" :product="selectedProduct" @close="closeModal" />
  </div>
</template>


<script>
import ProductModal from './ProductModal.vue';

export default {
  name: 'CarouselMain',
  components: {
    ProductModal
  },
  props: {
    carouselImages: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentIndex: 1, // Comenzamos desde la segunda diapositiva porque clonamos la última al inicio
      intervalId: null, // ID del intervalo para el auto-scroll
      transitionActive: true, // Controla si la transición está activa
      isTransitioning: false, // Evitar transiciones múltiples simultáneas
      startX: 0, // Coordenada X inicial del toque
      endX: 0, // Coordenada X final del toque durante el swipe
      showModal: false, // Controla la visibilidad del modal
      selectedProduct: null, // Almacena el producto seleccionado para el modal
      userInteractionTimeout: null // Temporizador para la interacción del usuario
    };
  },
  methods: {
    openModal(product) {
      this.selectedProduct = product; // Asigna el producto seleccionado
      this.showModal = true; // Muestra el modal
    },
    closeModal() {
      this.showModal = false; // Oculta el modal
    },
    startAutoScroll() {
      this.stopAutoScroll(); // Asegurarse de que no haya intervalos previos
      this.intervalId = setInterval(() => {
        this.nextSlide(true); // Pasar "true" para que se identifique como un auto-scroll
      }, 3000); // Cambiar de imagen cada 3 segundos
    },
    stopAutoScroll() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    prevSlide() {
      this.resetAutoScroll(); // Detener el auto-scroll al usar botones
      if (!this.isTransitioning) {
        this.isTransitioning = true; // Bloquea las transiciones adicionales hasta que termine
        this.transitionActive = true;
        this.currentIndex--;

        if (this.currentIndex === 0) {
          setTimeout(() => {
            this.transitionActive = false; // Desactiva la transición temporalmente para "saltar" sin animación
            this.currentIndex = this.carouselImages.length; // Saltamos al último slide real
          }, 500); // Después de la transición
        }

        setTimeout(() => {
          this.transitionActive = true; // Reactiva la transición
          this.isTransitioning = false; // Finaliza la transición
        }, 600); // Tiempo para asegurar que la transición se complete
      }
    },
    nextSlide() {
      this.resetAutoScroll(); // Detener el auto-scroll al usar botones
      if (!this.isTransitioning) {
        this.isTransitioning = true; // Bloquea las transiciones adicionales hasta que termine
        this.transitionActive = true;
        this.currentIndex++;

        // Si llegamos al último clon, hacemos la transición y luego saltamos a la primera diapositiva real sin animación
        if (this.currentIndex > this.carouselImages.length) {
          setTimeout(() => {
            this.transitionActive = false; // Desactiva la transición temporalmente para "saltar" sin animación
            this.currentIndex = 1; // Saltamos a la primera diapositiva real
          }, 500); // Después de la transición
        }

        setTimeout(() => {
          this.transitionActive = true; // Reactiva la transición
          this.isTransitioning = false; // Finaliza la transición
        }, 600); // Tiempo para asegurar que la transición se complete
      }
    },
    resetAutoScroll() {
      // Detenemos el auto-scroll cuando el usuario interactúa
      this.stopAutoScroll();

      // Si ya hay un temporizador en curso, lo limpiamos para evitar reinicios repetidos
      if (this.userInteractionTimeout) {
        clearTimeout(this.userInteractionTimeout);
      }

      // Reiniciamos el auto-scroll después de 3 segundos de inactividad
      this.userInteractionTimeout = setTimeout(() => {
        this.startAutoScroll();
      }, 3000); // El auto-scroll se reinicia después de 3 segundos
    },
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX; // Almacena la posición inicial del toque
    },
    handleTouchMove(event) {
      this.endX = event.touches[0].clientX; // Almacena la posición final del toque durante el movimiento
    },
    handleTouchEnd() {
      const diffX = this.startX - this.endX; // Calcula la diferencia de movimiento en el eje X

      // Si la diferencia es significativa, determina la dirección del swipe
      if (Math.abs(diffX) > 50) {
        if (diffX > 0) {
          // Swipe hacia la izquierda (siguiente diapositiva)
          this.nextSlide();
        } else {
          // Swipe hacia la derecha (diapositiva anterior)
          this.prevSlide();
        }
      }
    }
  },
  mounted() {
    this.startAutoScroll(); // Iniciar el auto-scroll cuando el componente esté montado
  },
  beforeDestroy() {
    this.stopAutoScroll(); // Detener el auto-scroll cuando el componente sea destruido
  }
};
</script>


<style scoped>
/* Estilos del carrusel */
.carousel-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  padding: 0 40px; /* Espacio a los lados para centrar mejor el contenido */
}

.carousel-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/src/assets/images/backgrounds/daa9991d-84c2-469c-960c-ad50a864e2cc.jepg.jpg'); /* Ruta de tu imagen de fondo */
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.carousel-products-wrapper {
  width: 100%;
  overflow: hidden; /* Oculta el desbordamiento del contenido */
  position: relative;
  z-index: 2; /* Coloca el contenido por encima del fondo */
}

.carousel-products {
  display: flex;
  transition: transform 0.5s ease-in-out; /* Transición suave entre diapositivas */
  width: 100%;
}

.carousel__item {
  min-width: 100%; /* Cada diapositiva ocupa el 100% del ancho del contenedor */
  display: flex;
  justify-content: space-between; /* Asegura que el contenido dentro esté distribuido */
  align-items: center;
  padding: 90px 100px; /* Añade espacio alrededor del contenido */
  padding-left: 180px;
}

.carousel-content-with-background {
  background-color: rgba(255, 255, 255, 0.7); /* Fondo con transparencia */
  padding: 20px;
  border-radius: 10px;
  width: 40%; /* Ancho fijo */
  height: 320px; /* Altura fija para todos los cuadros */
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: start; /* Alinea el contenido hacia el inicio */
  box-sizing: border-box; /* Incluye el padding dentro del ancho y altura */
  gap: 15px; /* Reduce el espacio entre los elementos */
}

.carousel-content-with-background img {
  width: 200px; /* Ancho fijo */
  height: 200px; /* Altura fija */
  object-fit: scale-down; /* Ajusta la imagen para cubrir el contenedor y mantener el tamaño */
}


.carousel-content-with-background p {
  text-align: justify; /* Justificar el texto dentro del párrafo */
  font-size: 1rem; /* Ajustar el tamaño del texto si es necesario */
  line-height: 1.5; /* Aumentar el espaciado entre líneas para mejor legibilidad */
  font-weight: bolder;
  text-align: justify
}
/* Estilos para el texto del carrusel */


.carousel-text h1 {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 2px;
}

.small-image {
  width: 200px; /* Ajusta el tamaño pequeño de la imagen */
  height: auto;
  object-fit: contain; /* Asegura que la imagen mantenga sus proporciones */
}

.carousel-text ul {
  list-style: none;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: justify;
  font-weight: bold;
  font-size: 1rem;
}

.carousel-text ul li {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.quote-button {
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid black;
  color: black;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1.1rem;
}

.quote-button:hover {
  background-color: black;
  color: white;
}

/* Estilos para las imágenes del carrusel */
.carousel-image {
  width: 45%; /* La imagen ocupa el 50% del ancho de la diapositiva */
}

.carousel-image img {
  width: 100%;
  object-fit: scale-down;
}

/* Botones de navegación */
.carousel__control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  background-color: transparent;
  color: white;
  border: none;
  padding: 2px;
  font-size: 2rem;
  cursor: pointer;
}

.carousel__control.prev {
  left: 20px;
}

.carousel__control.next {
  right: 20px;
}

/* --- Estilos Responsivos para Móviles --- */
/* Estilos Responsivos para Móviles */
@media (max-width: 768px) {
  .carousel-wrapper {
    padding: 0 20px; /* Reducir el padding lateral en móviles */
  }

  .carousel__item {
    flex-direction: column-reverse; /* Imagen primero, luego texto */
    text-align: center;
    padding: 20px 0;
    justify-content: center;
    display: flex;
  }

  .carousel-content-with-background {
    width: 100%; /* Ocupa todo el ancho en móviles */
    max-width: 100%; /* Limita el ancho */
    height: 300px; /* Ajusta la altura automáticamente */
    padding: 15px; /* Reduce el padding para mejorar el espacio */
    margin: 0 auto; /* Centra el contenido */
    gap: 10px; /* Reduce el espacio entre elementos */
  }

  .carousel-content-with-background img {
    width: 100px; /* Ajusta el tamaño de la imagen para móviles */
    height: 100px;
    object-fit: scale-down; /* Mantiene las proporciones de la imagen */
    margin-bottom: 10px; /* Espacio debajo de la imagen */
  }

  .carousel-text h1 {
    font-size: 2rem; /* Reduce el tamaño del título */
    line-height: 1.2;
    margin-bottom: 10px; /* Espacio inferior */
  }

  .carousel-text ul {
    font-size: 0.9rem; /* Ajusta el tamaño del texto */
  }

  .quote-button {
    margin-top: 10px;
    padding: 8px 16px; /* Botón más pequeño para móviles */
    font-size: 0.9rem;
  }

  .carousel-image {
    width: 280px; /* Ajusta el ancho de la imagen principal */
    height: 280px;
    object-fit: scale-down;
    margin: 0 auto 10px; /* Centra la imagen y añade margen inferior */
  }
}

</style>
