<template>
  <div class="product">
    <section class="product__title">
      <div class="product__title-image">
        <img src="@/assets/images/cover/red-and-black-abstract-elegant-geometric-background-hd-seenliirgq.jpg" alt="">
      </div>
      <div class="product__title-text">
        <h1>NUESTRAS MARCAS</h1>
      </div>
    </section>
    <div class="page-product">
      <div class="product-grid">
        <ProductCard
          v-for="product in products"
          :key="product.id"
          :product="product"
          @click="goToBrand(product)" 
        />
      </div>
      <ProductModal v-if="selectedProduct" :product="selectedProduct" @close="selectedProduct = null" />
    </div>
  </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';
import ProductModal from '@/components/ProductModal.vue';

import image1 from '@/assets/images/marcas/automann.png';
import image2 from '@/assets/images/marcas/DANA.png';
import image3 from '@/assets/images/marcas/DEVS_parts-removebg-preview.png';
import image4 from '@/assets/images/marcas/EATON.png';
import image5 from '@/assets/images/marcas/FERSA.png';
import image6 from '@/assets/images/marcas/kysor.png';
import image7 from '@/assets/images/marcas/PAI.png';
import image8 from '@/assets/images/marcas/SPICER.png';

export default {
  name: 'ProductPage',
  data() {
    return {
      products: [
        { id: 1, name: "AUTOMANN", image: image1, description: "Automann está completamente enfocado en mantener los camiones en movimiento y, al mismo tiempo, ayudar a sus clientes a avanzar en sus negocios.Desde 1994, esta empresa familiar, orientada al cliente, ha crecido al servicio de distribuidores, flotas y conductores que dependen de sus productos de alta calidad y su experiencia en ingeniería. Automann se compromete a escuchar a sus clientes y a brindarles lo que necesitan, con la confiabilidad, valor y rendimiento que esperan.Como pioneros en piezas para camiones y remolques, Automann ha construido su historia en la exploración: innovando continuamente, creando nuevos productos y encontrando oportunidades para servir mejor a sus clientes.La empresa tiene una gran pasión por su colaboración con la industria de vehículos pesados y continúa explorando nuevas formas de ayudar a sus clientes a seguir adelante." },
        { id: 2, name: "DANA", image: image2, description: "Dana Incorporated es un proveedor estadounidense de ejes, transmisiones, árboles de transmisión y equipos electrodinámicos, térmicos, de sellado y digitales para vehículos convencionales, híbridos y eléctricos. Sus productos y servicios están dirigidos a los mercados de vehículos ligeros, vehículos comerciales y maquinaria fuera de carretera. Fundada en 1904 y con sede en Maumee, Ohio, la empresa emplea a cerca de 36,000 personas en 33 países. En 2019, Dana generó ventas por 8,600 millones de dólares y forma parte del ranking Fortune 500." },
        { id: 3, name: "DEVS PARTS", image: image3, description: "Devs Parts se especializa en piezas de alta calidad para vehículos pesados." },
        { id: 4, name: "EATON", image: image4, description: "Eaton Corporation plc es una empresa multinacional de gestión de energía con sede en Estados Unidos e Irlanda, y su principal centro administrativo se encuentra en Beachwood, Ohio. Eaton cuenta con más de 85,000 empleados y vende sus productos a clientes en más de 175 países." },
        { id: 5, name: "FERSA", image: image5, description: "Los orígenes de Fersa Bearings se remontan a 1968, cuando dos empresas familiares del sector automotriz unieron fuerzas para crear A&S FERDSA, cuyo desarrollo condujo a la fundación de Fersa Bearings.El rigor, la calidad y la modernización de sus procesos de producción, junto con una inversión continua en I+D, han permitido a Fersa convertirse en un fabricante de equipos originales (OEM) y en un proveedor aprobado de nivel Tier1, posicionándose entre las marcas líderes en el mercado de posventa.La presencia internacional de Fersa crece cada día y actualmente alcanza más de 85 países. Su extensa red de distribución global, así como la presencia de sus propios centros de fabricación y oficinas de ingeniería y ventas en mercados estratégicos, han permitido que la marca Fersa continúe expandiéndose a nivel mundial año tras año." },
        { id: 6, name: "KYSOR", image: image6, description: "Kysor ofrece sistemas de refrigeración para vehículos." },
        { id: 7, name: "PAI INDUSTRIES", image: image7, description: "PAI Industries fabrica y distribuye piezas de servicio de alta calidad para la industria de camiones de servicio pesado. Durante más de 50 años, ha suministrado productos a distribuidores en todo el mundo. PAI debe su éxito notable a sus empleados dedicados y con experiencia. Con décadas de experiencia en el diseño y prueba de productos en el campo, tanto su equipo de Ingeniería como el de Ventas están disponibles para responder a las preguntas técnicas más desafiantes.El compromiso de PAI con sus clientes se refleja en el mantenimiento de los más altos niveles de calidad en los productos y servicios que ofrece. La calidad, la mejora continua y la satisfacción del cliente son la responsabilidad personal de cada empleado de PAI." },
        { id: 8, name: "SPICER", image: image8, description: "Spicer es reconocida por sus componentes de transmisión de alta calidad." }
      ],
      selectedProduct: null
    };
  },
  methods: {
    goToBrand(product) {
      this.$router.push({ 
        name: 'BrandProductsPage', 
        params: { 
          brand: product.name, 
          image: product.image, 
          description: product.description 
        } 
      });
    },
    openModal(product) {
      this.selectedProduct = product;
    }
  },
  components: {
    ProductCard,
    ProductModal
  }
};
</script>

<style >

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

/* Estilo para el título y la imagen */
.product__title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.product__title-image {
  width: 100vw;
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.product__title-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.product__title-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  padding: 20px;
  z-index: 10;
  font-size: 30px;
  text-align: center;
  width: 100%;
  letter-spacing: 20px;
}

@media (max-width: 768px) {
  .product__title-image {
    height: 28vh;
  }

  .product__title-text {
    font-size: 20px;
    letter-spacing: 10px;
  }
}

/* Estilo para las cards del producto */
.page-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  padding: 20px;
  max-width: 1200px;
}

/* Para pantallas pequeñas */
@media (max-width: 768px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr); /* Dos columnas en pantallas móviles */
  }
}

.product-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  overflow: hidden;
}

.product-card img {
  width: 100%;
  height: 150px;
  object-fit: contain;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.product-card img:hover {
  filter: grayscale(0%);
}

.product-card h3, .product-card p {
  margin: 5px 0;
}
</style>
