<template>
  <div class="contact">
    <main>
      <section class="contact__title">
        <div class="contact__title-image">
          <img src="@/assets/images/cover/red-and-black-abstract-elegant-geometric-background-hd-seenliirgq.jpg" alt="">
        </div>
        <div class="contact__title-text">
          <h1>CONTACTANOS</h1>
        </div>
      </section>
      <div class="from-and-office">
        <form @submit.prevent="sendContactInfo">
          <h2>Formulario de Contacto</h2>
          <div class="form-group">
            <input v-model="nombre" type="text" id="nombre" name="nombre" placeholder="Nombre y Apellidos" autocomplete="off" required>
          </div>
          <div class="form-group">
            <input v-model="dni" type="number" id="dni" name="dni" placeholder="DNI" autocomplete="off" required>
          </div>
          <div class="form-group">
            <input v-model="empresa" type="text" id="empresa" name="empresa" placeholder="Empresa" autocomplete="off">
          </div>
          <div class="form-group">
            <input v-model="ruc" type="number" id="ruc" name="ruc" placeholder="RUC" autocomplete="off">
          </div>
          <div class="form-group">
            <input v-model="correo" type="email" id="correo" name="correo" placeholder="Correo Electrónico" autocomplete="off" required>
          </div>
          <div class="form-group">
            <input v-model="telefono" type="tel" id="telefono" name="telefono" placeholder="Teléfono" autocomplete="off" required>
          </div>
          <div class="form-group textarea-group">
            <textarea v-model="mensaje" id="mensaje" name="mensaje" rows="4" placeholder="Mensaje" autocomplete="off" required></textarea>
          </div>
          <div class="form-group-checkbox">
            <label>
              <input type="checkbox" v-model="confirmacion" id="confirmacion" name="confirmacion">
              Confirmo que mis datos son reales.
            </label>
          </div>
          <div class="form-group-button">
            <button type="submit">Enviar</button>
          </div>
        </form>
        <div class="office-info">
          <h2>Ubicanos</h2>
          <div>
            <h3>Tienda San Luis</h3>
            <p>Av. Nicolas Arriola 1894, San Luis, Lima</p>
            <a href="https://maps.app.goo.gl/6ATSo2Ct76wZCJRx9">Abrir ubicación en Google Maps</a>
            <p>Email: ventas@irsadperu.com</p>
            <p>Teléfono: (+51) 998 307 576</p>
          </div>
          <div>
            <h3>Tienda Ate</h3>
            <p>Av. Separadora Industrial 2960 Ate 15012, Lima</p>
            <a href="https://maps.app.goo.gl/5HvzDfFvygxq6Wog7">Abrir ubicación en Google Maps</a>
            <p>Email: ventas@irsadperu.com</p>
            <p>Teléfono: (+51) 923 526 740</p>
          </div>
          <div>
            <h3>Tienda Arequipa</h3>
            <p>Calle Alberto Guillen 720 Arequipa 04002, Arequipa</p>
            <a href="https://maps.app.goo.gl/yEq8x25VUdhd3MvD7">Abrir ubicación en Google Maps</a>
            <p>Email: ventas@irsadperu.com</p>
            <p>Teléfono: (+51) 939 187 067</p>
          </div>
          <div>
            <h3>Tienda Arequipa - Cono Norte</h3>
            <p>Calle San Agustin Km. 9 Lote 11 - Asoc. Santa Maria, Cerro Colorado, Arequipa</p>
            <a href="https://maps.app.goo.gl/WrWFoeFHhpJRgQZa7">Abrir ubicación en Google Maps</a>
            <p>Email: ventas@irsadperu.com</p>
            <p>Teléfono: (+51) 953 002 951</p>
          </div>
        </div>
      </div>
      <!-- Mostrar error en la pantalla -->
      <div v-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>
    </main>
  </div>
</template>

<script>
/* global grecaptcha */ // Añade esta línea para informar a ESLint sobre la variable global

import axios from 'axios';

export default {
  name: 'ContactPage',
  data() {
    return {
      nombre: '',
      dni: '',
      empresa: '',
      ruc: '',
      correo: '',
      telefono: '',
      mensaje: '',
      confirmacion: false,
      errorMessage: '',  // Mensaje de error
      recaptchaLoaded: false,  // Verificar si reCAPTCHA está cargado
    };
  },
  methods: {
    async sendContactInfo() {
      // Validaciones en el frontend
      if (!this.confirmacion) {
        this.errorMessage = 'Debes confirmar que tus datos son reales antes de enviar el formulario.';
        return;
      }

      // Validación de DNI
      if (this.dni.length !== 8) {
        this.errorMessage = 'El DNI debe tener exactamente 8 dígitos.';
        return;
      }

      // Validación de RUC
      if (this.ruc && this.ruc.length !== 11) {
        this.errorMessage = 'El RUC debe tener exactamente 11 dígitos.';
        return;
      }

      // Validación del formato del correo
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.correo)) {
        this.errorMessage = 'Por favor, ingresa un correo electrónico válido.';
        return;
      }

      // Validación del número de teléfono
      if (this.telefono.length < 7 || this.telefono.length > 9) {
        this.errorMessage = 'El número de teléfono debe tener entre 7 y 9 dígitos.';
        return;
      }

      // Validación de campos vacíos
      if (!this.nombre.trim() || !this.mensaje.trim()) {
        this.errorMessage = 'El nombre y el mensaje no pueden estar vacíos.';
        return;
      }

      try {
        if (!this.recaptchaLoaded) {
          this.errorMessage = 'reCAPTCHA no se ha cargado. Inténtalo de nuevo.';
          return;
        }

        const recaptchaToken = await this.getRecaptchaToken(); // Obtener el token reCAPTCHA

        // Parámetros del formulario
        const params = new URLSearchParams();
        params.append('dni', this.dni);
        params.append('nombre', this.nombre);
        params.append('empresa', this.empresa);
        params.append('ruc', this.ruc);
        params.append('correo', this.correo);
        params.append('telefono', this.telefono);
        params.append('mensaje', this.mensaje);
        params.append('confirmacion', this.confirmacion);
        params.append('recaptcha_token', recaptchaToken); // Incluir el token de reCAPTCHA

        const response = await axios.post('https://irsadperu.com/backend/contacto.php', params, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });

        if (response.status === 200 && response.data.message) {
          alert("Formulario enviado con éxito: " + response.data.message);
          this.clearForm();
          this.errorMessage = '';  // Limpiar el mensaje de error
        } else {
          this.errorMessage = 'No se recibió una respuesta exitosa del servidor.';
        }
      } catch (error) {
        if (error.response && error.response.data) {
          this.errorMessage = error.response.data.error || 'Error desconocido en el servidor.';
        } else {
          this.errorMessage = 'Error de red o el servidor no está disponible.';
        }
      }
    },
    async getRecaptchaToken() {
  return new Promise((resolve, reject) => {
    if (typeof grecaptcha !== 'undefined') {
      grecaptcha.ready(() => {
        grecaptcha.execute('6LcOoTYqAAAAAPvBeEudequ7rd54J82g2PZewgia', { action: 'submit' }).then(token => { // Site key aquí
          resolve(token);
        }).catch(error => {
          this.errorMessage = 'Error al generar el token reCAPTCHA.';
          reject(error);
        });
      });
    } else {
      this.errorMessage = 'reCAPTCHA no está disponible.';
      reject(new Error('reCAPTCHA no está disponible.'));
    }
  });
},
    clearForm() {
      this.nombre = '';
      this.dni = '';
      this.empresa = '';
      this.ruc = '';
      this.correo = '';
      this.telefono = '';
      this.mensaje = '';
      this.confirmacion = false;
    },
    loadRecaptchaScript() {
      return new Promise((resolve, reject) => {
        if (document.getElementById('recaptcha-script')) {
          resolve(); // El script ya está cargado
        } else {
          const script = document.createElement('script');
          script.id = 'recaptcha-script';
          script.src = 'https://www.google.com/recaptcha/api.js?render=6LcOoTYqAAAAAPvBeEudequ7rd54J82g2PZewgia';
          script.async = true;
          script.onload = () => {
            this.recaptchaLoaded = true;
            resolve();
          };
          script.onerror = () => {
            this.errorMessage = 'Error al cargar reCAPTCHA';
            reject();
          };
          document.head.appendChild(script);
        }
      });
    }
  },
  mounted() {
    this.loadRecaptchaScript();
  }
};
</script>

<style scoped>
.error-message {
  color: red;
  margin-top: 10px;
  font-weight: bold;
}
</style>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.contact {
  background-color: white; /* Fondo blanco para todo */
}

.contact__title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.contact__title-image {
  width: 100vw;
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.contact__title-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que la imagen cubra el contenedor sin deformarse */
  display: block;
}

.contact__title-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  padding: 20px;
  z-index: 10;
  font-size: 30px;
  text-align: center;
  width: 100%;
  letter-spacing: 20px;
}

@media (max-width: 768px) {
  .contact__title-image {
    height: 28vh;
  }

  .contact__title-text {
    font-size: 20px;
    letter-spacing: 10px;
  }
}

/* Contenedor principal del formulario y la sección de oficinas */
.from-and-office {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Formulario y oficinas ocupan cada uno 50% del ancho */
  gap: 20px; /* Espacio entre el formulario y la oficina */
  align-items: start; /* Alinea ambos elementos en la parte superior */
  padding: 20px;
  background-color: white;
}

/* Estilos del formulario */
form {
  grid-column: 1;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas iguales */
  gap: 20px; /* Espacio entre columnas */
}

form h2 {
  grid-column: span 2; /* El título ocupa las dos columnas */
  text-align: left;
  font-size: 24px;
}

form .form-group {
  display: flex;
  flex-direction: column;
}

form .form-group.textarea-group {
  grid-column: span 2; /* Contenedor del textarea ocupa las dos columnas */
}

form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-family: "Times New Roman", Times, serif;
  color: black;
  box-sizing: border-box;
}

form .form-group-checkbox {
  display: flex;
  align-items: center;
  grid-column: span 2; /* Ocupa ambas columnas */
}

form input[type="text"],
form input[type="number"],
form input[type="email"],
form input[type="tel"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-family: "Times New Roman", Times, serif;
  color: black;
  box-sizing: border-box;
}

form input[type="number"]::-webkit-inner-spin-button,
form input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

form input[type="number"] {
  -moz-appearance: textfield;
}

form .form-group-checkbox input[type="checkbox"] {
  margin-right: 10px;
}

form .form-group-checkbox label {
  margin: 0;
  font-size: 16px;
}

form .form-group-button {
  grid-column: span 2; /* Ocupa ambas columnas */
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

form button {
  display: block;
  width: 150px;
  padding: 10px 20px;
  background: #e41b23;
  color: white;
  text-align: center;
  font-weight: 500;
  border-radius: 8px;
  border: 2px solid transparent;
  cursor: pointer;
}

form button:hover {
  background: white;
  border: 2px solid #bb0000;
  color: black;
}

.office-info {
  grid-column: 2;
  max-width: 100%;
  padding: 20px;
  background-color: white;
  border-left: 5px solid red;
  font-family: "Arial", sans-serif;
}

.office-info h2 {
  font-size: 30px;
  font-weight: bolder;
  color: black;
  margin-bottom: 20px;
}

.office-info h3 {
  font-size: 20px;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}

.office-info p,
.office-info a {
  font-size: 16px;
  color: black;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.office-info a {
  color: #8B0000;
  text-decoration: none;
}

.office-info a:hover {
  text-decoration: underline;
}

.office-info p::before,
.office-info a::before {
  content: '';
  margin-right: 8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.office-info a:nth-child(3)::before {
  background-image: url('https://img.icons8.com/ios-filled/50/8B0000/marker.png');
}

.office-info p:nth-child(4)::before {
  background-image: url('https://img.icons8.com/ios-filled/50/8B0000/new-post.png');
}

.office-info p:nth-child(5)::before {
  background-image: url('https://img.icons8.com/ios-filled/50/8B0000/phone.png');
}

@media (max-width: 768px) {
  .from-and-office {
    grid-template-columns: 1fr;
    padding: 0;
  }

  form {
    grid-column: 1;
    grid-template-columns: 1fr;
    width: 100%;
  }

  form .form-group {
    grid-column: 1;
  }

  form textarea {
    grid-column: 1;
  }

  form h2 {
    grid-column: 1;
  }

  .office-info {
    grid-column: 1;
    margin-top: 20px;
  }
}
</style>
