<template>
  <div class="about">
    <main>
      <section class="about__title">
      <div class="about__title-image">
        <img src="@/assets/images/cover/red-and-black-abstract-elegant-geometric-background-hd-seenliirgq.jpg" alt="">
      </div>
      <div class="about__title-text">
        <h1>NOSOTROS</h1>
      </div>
    </section>
      <section class="business__about">
        <div class="business__about-image">
          <img src="@/assets/images/backgrounds/ai-generated-trucks-at-sun-down-free-photo.jpg" alt="Imagen 1">
        </div>
        <div class="business__about-text">
          <h2>NOSOTROS</h2>
          <p>Fundada en 2005, IRSAD Perú se especializa en la importación y comercialización de partes y repuestos de alta calidad para camiones. Con más de una década de experiencia en el mercado, hemos consolidado una sólida reputación gracias a nuestro compromiso con la excelencia y la satisfacción de nuestros clientes.</p>
        </div>
      </section>

      <section class="business__about reverse">
        <div class="business__about-image">
          <img src="@/assets/images/backgrounds/167005c6-2d7c-4557-a58c-61cabd8a322d.jpeg" alt="Imagen 2">
        </div>
        <div class="business__about-text">
          <h2>MISIÓN</h2>
          <p>Nuestra misión es servirle a su plena satisfacción, ofreciéndoles productos de excelente calidad y soluciones innovadoras que aseguren el mejor rendimiento para sus vehículos.</p>
        </div>
      </section>

      <section class="business__about">
        <div class="business__about-image">
          <img src="@/assets/images/backgrounds/istockphoto-1212230930-2048x2048.jpg" alt="Imagen 3">
        </div>
        <div class="business__about-text">
          <h2>VISIÓN</h2>
          <p>Ser líderes en el mercado de partes y repuestos para camiones, expandiendo nuestra presencia y manteniendo nuestro compromiso con la innovación y la sostenibilidad.</p>
        </div>
      </section>

      <section class="business__about reverse">
        <div class="business__about-image">
          <img src="@/assets/images/backgrounds/1828c9b9-e4b4-4991-96f4-9eff1b57bcb5.jpeg" alt="Imagen 4">
        </div>
        <div class="business__about-text">
          <h2>SERVICIOS</h2>
          <p>Ofrecemos una amplia gama de servicios, desde la importación hasta la distribución de partes y accesorios para camiones, asegurando calidad y disponibilidad constante.</p>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
  mounted() {
    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.3
    });

    this.$nextTick(() => {
      document.querySelectorAll('.business__about-text').forEach(element => {
        observer.observe(element);
      });
    });
  }
};
</script>

<style scoped>
/* Ajustes generales */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Estilo para el título y la imagen */
.about__title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.about__title-image{
  width: 100vw;
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.about__title-image img{
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que la imagen cubra el contenedor sin deformarse */
  display: block;
}

.about__title-text{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  padding: 20px;
  z-index: 10;
  font-size: 30px;
  text-align: center;
  width: 100%;
  letter-spacing: 20px;
}

@media (max-width: 768px) {
  .about__title-image {
    height: 28vh;
  }

  .about__title-text {
    font-size: 20px;
    letter-spacing: 10px;
  }
}

/* Sección de negocios */
.business__about {
  display: flex;
  align-items: center;
  padding: 50px 0;
  background-color: whitesmoke;
  border-bottom: 1px solid #ddd;
}

.business__about-image {
  flex: 1;
  padding-right: 30px;
  margin-left: 20px; /* Aumenta el espacio entre la imagen y el borde izquierdo */
  margin-right: 20px; /* Aumenta el espacio entre la imagen y el borde derecho */
  max-width: 40%; /* Tamaño reducido al 40% de la página */
  height: auto;
}

.business__about-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}

.business__about-text {
  flex: 1;
  padding-left: 50px; /* Mayor espacio en los lados del texto */
  padding-right: 50px; /* Mayor espacio en los lados del texto */
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
}

.business__about-text h2 {
  font-size: 2.8rem;
  color: black;
  text-align: center;
}

.business__about-text p {
  font-size: 1.4rem;
  color: #333;
  margin-top: 10px;
  text-align: justify; /* Justificación del texto */
}

/* Sección inversa */
.business__about.reverse {
  flex-direction: row-reverse;
  padding: 50px 0;
  background-color: whitesmoke;
  border-bottom: 1px solid #ddd;
}

.business__about.reverse .business__about-image {
  padding-left: 30px;
  padding-right: 0;
  margin-left: 20px; /* Aumenta el espacio entre la imagen y el borde izquierdo */
  margin-right: 20px; /* Aumenta el espacio entre la imagen y el borde derecho */
  max-width: 40%; /* Tamaño reducido al 40% de la página */
}

.business__about.reverse .business__about-text {
  padding-right: 50px; /* Mayor espacio en los lados del texto */
  padding-left: 50px; /* Mayor espacio en los lados del texto */
}

@media (max-width: 768px) {
  .business__about,
  .business__about.reverse {
    flex-direction: column;
    text-align: center;
  }

  .business__about-image,
  .business__about.reverse .business__about-image {
    padding: 0;
    margin-bottom: 20px;
    max-width: 100%; /* Imagen ocupa todo el ancho en pantallas pequeñas */
    height: auto;
  }

  .business__about-text,
  .business__about.reverse .business__about-text {
    padding: 0 20px;
    max-width: 90%;
  }
}
</style>
