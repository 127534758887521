<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-content">
      <!-- Botón de cerrar (X) en la esquina superior derecha -->
      <button class="close-button" @click="close">&times;</button>

      <div class="modal-header">
        <h2>{{ product.name }}</h2>
      </div>

      <div class="modal-body">
        <!-- Sección de imagen con botón de lupa para hacer zoom -->
        <div class="modal-image">
          <img
            :src="product.image"
            :alt="product.name"
            class="zoomable-image"
            :class="{ 'zoom-active': isZoomed }"
            ref="image"
            @mousedown="startDragging"
            @mouseup="stopDragging"
            @mouseleave="stopDragging"
            @mousemove="dragImage"
            @touchstart="startDragging"
            @touchend="stopDragging"
            @touchmove="dragImage"
            @dragstart.prevent  
          />
          <!-- Botón de lupa en la esquina superior derecha -->
          <button class="zoom-button" @click="toggleZoom">
            🔍
          </button>
        </div>

        <div class="modal-details">
          <h3>{{ product.shortDescription }}</h3>
          <p>{{ product.description }}</p>

          <!-- Detalles del producto -->
          <ul class="product-info">
            <li><strong>SKU:</strong> {{ product.sku }}</li>
            <li><strong>Marca:</strong> {{ product.brand }}</li>
            <li><strong>Categorías:</strong> {{ product.categories }}</li>
            <li><strong>Etiquetas:</strong> {{ product.tags }}</li>
          </ul>

          <!-- Botones para descargar ficha técnica y cotizar -->
          <div class="action-buttons">
            <button class="download-button">Descargar ficha técnica</button>
            <button class="quote-button">Cotizar ahora</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: Object
  },
  data() {
    return {
      isZoomed: false, // Controlar si la imagen está ampliada o no
      isDragging: false, // Controlar si se está arrastrando la imagen
      startX: 0,
      startY: 0,
      translateX: 0,
      translateY: 0
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    toggleZoom() {
      this.isZoomed = !this.isZoomed; // Alternar zoom

      // Si se activa el zoom, aplicar la transformación de zoom (2x)
      if (this.isZoomed) {
        this.$refs.image.style.transform = `translate(0px, 0px) scale(2)`;
      } else {
        // Si se desactiva el zoom, restablecer la transformación
        this.translateX = 0;
        this.translateY = 0;
        this.$refs.image.style.transform = `translate(0px, 0px) scale(1)`;
      }
    },
    startDragging(event) {
      if (this.isZoomed) {
        this.isDragging = true;
        const isTouch = event.type === 'touchstart';
        this.startX = (isTouch ? event.touches[0].clientX : event.clientX) - this.translateX;
        this.startY = (isTouch ? event.touches[0].clientY : event.clientY) - this.translateY;
        event.preventDefault(); // Evitar comportamientos de selección de texto
      }
    },
    stopDragging() {
      this.isDragging = false;
    },
    dragImage(event) {
      if (this.isDragging) {
        const isTouch = event.type === 'touchmove';
        const clientX = isTouch ? event.touches[0].clientX : event.clientX;
        const clientY = isTouch ? event.touches[0].clientY : event.clientY;
        this.translateX = clientX - this.startX;
        this.translateY = clientY - this.startY;
        this.$refs.image.style.transform = `translate(${this.translateX}px, ${this.translateY}px) scale(2)`; // Mantener el zoom mientras se arrastra
      }
    }
  }
};
</script>

<style scoped>
/* Aquí tu estilo permanece igual */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  width: 90%;
  display: flex;
  flex-direction: column;
  position: relative; /* Permitir posicionar el botón de cierre */
}

.modal-header h2 {
  color: #333;
  font-size: 22px;
  margin-bottom: 10px;
}

.modal-body {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.modal-image {
  width: 100%;
  max-width: 300px;
  overflow: hidden; /* Para ocultar el desbordamiento cuando se hace zoom */
  position: relative;
}

.modal-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-details h3 {
  color: #e41b23;
  font-size: 18px;
}

.modal-details p {
  line-height: 1.5;
  margin-bottom: 15px;
}

.product-info {
  list-style: none;
  padding: 0;
}

.product-info li {
  margin-bottom: 8px;
}

.product-info li strong {
  font-weight: bold;
}

.action-buttons {
  margin-top: 20px;
  display: flex;
  gap: 15px;
}

.download-button, .quote-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  flex-grow: 1;
}

.download-button {
  background-color: #007bff;
  color: white;
}

.download-button:hover {
  background-color: #0056b3;
}

.quote-button {
  background-color: #e10000;
  color: white;
}

.quote-button:hover {
  background-color: #c10000;
}

/* Botón de cierre (X) en la esquina superior derecha */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 30px; /* Hacer la X más grande */
  color: #333;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
}

.close-button:hover {
  color: #e10000;
  transform: scale(1.3); /* Efecto hover para hacer más grande la X */
}

/* Botón de lupa para el zoom */
.zoom-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  transition: background 0.3s ease;
}

.zoom-button:hover {
  background: rgba(255, 255, 255, 1);
}

/* Imagen zoomable */
.zoomable-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease; /* Transición suave al hacer zoom */
  user-select: none; /* Evitar la selección de la imagen */
  pointer-events: none; /* Deshabilitar eventos predeterminados de arrastre */
}

.zoom-active {
  cursor: grab; /* Indicar que la imagen puede ser arrastrada */
  pointer-events: auto; /* Rehabilitar eventos para el zoom */
}

.zoomable-image.zoom-active {
  transform: scale(2); /* Escala de zoom al activarlo */
}
</style>
