<template>
  <div>
    <TopBar ref="topBar" />
    <header :style="{ top: headerTop + 'px' }">
      <div class="container__header">
        <div class="logo">
          <router-link to="/">
            <img :src="require('@/assets/images/logo/358710894_714048067401803_7472546727404385508_n.jpg')" alt="Logo">
          </router-link>
        </div>
        <div :class="['menu', { active: menuActive }]">
          <nav class="nav__menu">
            <ul>
              <li :class="{ active: selectedOption === '/' }">
                <router-link to="/" @click.native="selectOption('/')">Inicio</router-link>
              </li>
              <li :class="{ active: selectedOption === '/about' }">
                <router-link to="/about" @click.native="selectOption('/about')">Nosotros</router-link>
              </li>
              <li :class="{ active: selectedOption === '/contact' }">
                <router-link to="/contact" @click.native="selectOption('/contact')">Contactos</router-link>
              </li>
              <li :class="{ active: selectedOption === '/product' }">
                <router-link to="/product" @click.native="selectOption('/product')">Marcas</router-link>
              </li>
              <li class="ecommerce-construction">
  <a href="#" @click.prevent="emitScrollToAbout">
    E-commerce
    <span class="warning-icon">⚠️</span>
    <small>En construcción</small>
  </a>
</li>

            </ul>
          </nav>
        </div>
        <div class="hamburger menu-toggle" @click="toggleMenu">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </header>
  </div>
</template>


<script>
import TopBar from './TopBar.vue';

export default {
  name: 'AppHeader',
  components: {
    TopBar,
  },
  data() {
    return {
      headerTop: 0, // Espacio superior del header
      selectedOption: '/', // Opción seleccionada en el menú
      menuActive: false, // Controla si el menú está abierto
      topBarVisible: true, // Visibilidad del TopBar
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
    // Inicializar selectedOption con la ruta actual
    this.selectedOption = this.$route.path;
  },
  watch: {
    // Observa los cambios de ruta y actualiza selectedOption
    $route(to) {
      this.selectedOption = to.path;
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const header = document.querySelector('header');
      const scrollPosition = window.scrollY;

      if (window.innerWidth > 768) {
        if (scrollPosition > 50) {
          this.headerTop = 0;
          this.topBarVisible = false;
        } else {
          this.headerTop = 40;
          this.topBarVisible = true;
        }
      } else {
        this.headerTop = 0;
        this.topBarVisible = false;
      }

      if (scrollPosition > 0) {
        header.classList.add('header--sticky');
        /* header.classList.remove('header--transparent'); */
      } else {
        header.classList.add('header--sticky');
        /* header.classList.remove('header--sticky'); */
      }

      if (scrollPosition > 0) {
        header.classList.add('fixed');
      } else {
        header.classList.remove('fixed');
      }
    },
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
    selectOption(option) {
      this.selectedOption = option; // Cambia la opción seleccionada
      this.menuActive = false; // Cierra el menú después de seleccionar una opción
    },
    emitScrollToAbout() {
    this.$emit('scrollToAbout');
  }
  },
};
</script>

<style scoped>
/* Estilos generales para el header */
header {
  position: fixed;
  z-index: 100;
  top: 40px;
  width: 100%;
  transition: top 0.1s ease-in-out, background 0.1s ease-in-out;
  overflow: hidden;
  display: flex;
  align-items: center; /* Asegurar la alineación vertical en el header */
}

.header--transparent {
  background: transparent;
}

.header--sticky {
  background: white;
}

/* Contenedor del header */
.container__header {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between; /* Distribuir elementos: logo a la izquierda, menú centrado */
  align-items: center;
  padding: 0 20px;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box; /* Asegurar que el padding se incluya en el ancho total */
}

/* Logo Styling */
.logo img {
  height: 63px;
  width: 110px;
}

/* Menu Styling */
.menu {
  flex-grow: 1;
  display: flex;
  justify-content: center; /* Centrar las opciones del menú en versión escritorio */
  align-items: center; /* Alinear verticalmente los items del menú */
  transition: max-height 0.3s ease-in-out;
  padding: 0;
  margin: 0;
  overflow-x: hidden; /* Asegurar que el menú no cause desbordamiento horizontal */
}

.menu nav {
  margin: 0;
  padding: 0;
  width: 100%; /* Asegurar que el contenedor del menú ocupe el 100% del ancho */
  display: flex;
  justify-content: center;
  align-items: center; /* Alinear verticalmente todas las opciones del menú */
}

/* Estilos para la versión de escritorio */
.nav__menu ul {
  display: flex;
  list-style: none;
  justify-content: center; /* Asegurar que las opciones estén centradas en escritorio */
  width: 100%; /* Ocupar el ancho completo */
  margin: 0;
  padding: 0;
  align-items: center; /* Alinear verticalmente todas las opciones del menú */
}

.nav__menu ul li {
  margin: 0 30px; /* Aumentar la separación entre las opciones en escritorio */
  display: flex;
  align-items: center; /* Centrar verticalmente los ítems del menú */
}

.nav__menu ul li a {
  color: black;
  font-size: 16px;
  font-weight: bolder;
  text-decoration: none;
  padding: 10px 15px;
  text-align: center;
  margin: 0;
  display: inline-block; /* Asegura que el ancho del enlace no cause desbordamientos */
  box-sizing: border-box; /* Incluye el padding en el ancho total */
}

/* Estilo para la sección de e-commerce en construcción */
.ecommerce-construction {
  display: flex;
  align-items: center; /* Alinear el contenido del e-commerce verticalmente */
  flex-direction: column;
  text-align: center;
  margin-left: auto;
}

.ecommerce-construction .warning-icon {
  font-size: 16px;
  color: #e41b23; /* Color de advertencia */
  margin-left: 5px;
}

.ecommerce-construction small {
  display: block;
  font-size: 12px;
  color: #e41b23;
  margin-top: -3px;
  font-weight: normal;
}

/* Estilos para el hover y activo en versión de escritorio */
.nav__menu ul li a:hover {
  color: #e41b23; /* Cambia el texto a rojo al hacer hover */
}

.nav__menu ul li.active {
  border-bottom: 4px solid #e41b23; /* Subrayado rojo en el borde inferior del contenedor */
  margin-bottom: -10px; /* Mueve el subrayado hacia el borde inferior sin desalinear el texto */
}

.nav__menu ul li.active a {
  color: #e41b23; /* Texto rojo para la opción activa */
}

/* Estilos para el menú en pantallas pequeñas */
@media (max-width: 768px) {
  .nav__menu ul {
    flex-direction: column; /* Alinear opciones en columna */
    align-items: flex-start; /* Alinear texto a la izquierda */
    width: 100%;
    background-color: white;
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Incluir padding dentro del ancho total */
  }

  .nav__menu ul li {
    width: 100%; /* Cada opción ocupa el 100% del ancho */
    margin: 0;
    padding: 0;
  }

  .nav__menu ul li a {
    width: 100%; /* El enlace ocupa todo el ancho */
    padding: 15px; /* Asegurar que el padding esté dentro del enlace */
    text-align: left; /* Alinear el texto a la izquierda */
    box-sizing: border-box; /* Incluir el padding dentro del ancho total */
    display: block; /* Asegurar que el enlace ocupe todo el bloque */
  }

  .nav__menu ul li.active a {
    background-color: #e41b23; /* Fondo negro para la opción activa */
    color: white;
  }

  .nav__menu ul li a:hover {
    background-color: #e41b23; /* Fondo negro al hacer hover */
    color: white; /* Texto blanco */
  }

  header {
    top: 0;
    background: /* #e41b23 */ white !important;
    padding: 0;
    width: 100vw; /* Aseguramos que no haya desbordamiento en pantallas móviles */
  }

  .header--sticky {
    background: white;
  }

  .logo {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 300;
  }

  .menu {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100vw; /* Aseguramos que el menú ocupe todo el ancho en móviles */
    background-color: #e41b23;
    display: block;
    z-index: 200;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding: 0;
    margin: 0;
  }

  .menu.active {
    max-height: 100vh;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .hamburger span {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 4px 0;
    transition: all 0.3s ease;
  }
}

</style>