<template>
  <div id="app">
    <AppHeader />
    <router-view />
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>

<style>

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5; /* Cambia a tu color de fondo deseado */
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Asegura que el #app ocupe al menos toda la altura de la ventana */
}

/* Asegura que todos los enlaces en la aplicación no tengan subrayado */
a {
  text-decoration: none;
  color: inherit; /* Mantiene el color del enlace según su contexto */
}

/* También puedes asegurarte de que los pseudo-selectores no apliquen subrayado */
a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}

main, router-view {
  flex-grow: 1; /* Asegura que el contenido principal ocupe el espacio disponible */
}

</style>
