<template>
  <div class="carousel-outer-wrapper">
    <!-- Este contenedor manejará el espacio alrededor del carrusel -->
    <div class="carousel-wrapper-brands">
      <div class="carousel-brands" ref="carousel">
        <div class="image__brands" ref="imageBrands">
          <img v-for="(brand, index) in brands" :key="index" :src="brand" :alt="'Brand ' + (index + 1)" draggable="false">
          <!-- Clonar las imágenes para el efecto infinito -->
          <img v-for="(brand, index) in brands" :key="'clone-' + index" :src="brand" :alt="'Brand ' + (index + 1)" draggable="false">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarouselBrands',
  props: ['brands'],
  mounted() {
    this.initializeCarousel();
  },
  methods: {
    initializeCarousel() {
      const carousel = this.$refs.carousel;
      const imageBrands = this.$refs.imageBrands;
      let isDragging = false;
      let startX, scrollLeft;
      let autoScroll;

      const preventDefault = (e) => e.preventDefault();

      const startAutoScroll = () => {
        clearInterval(autoScroll);
        autoScroll = setInterval(() => {
          carousel.scrollLeft += 1;
          
          if (carousel.scrollLeft >= imageBrands.scrollWidth / 2) {
            carousel.scrollLeft = 0;
          }
        }, 20);
      };

      const startDragging = (e) => {
        isDragging = true;
        // Detectar si es evento touch o mouse
        startX = (e.pageX || e.touches[0].pageX) - carousel.offsetLeft;
        scrollLeft = carousel.scrollLeft;
        carousel.style.cursor = 'grabbing';
        clearInterval(autoScroll);

        document.addEventListener('mousemove', preventDefault);
        document.addEventListener('selectstart', preventDefault);
      };

      const stopDragging = () => {
        isDragging = false;
        carousel.style.cursor = 'grab';
        startAutoScroll();

        document.removeEventListener('mousemove', preventDefault);
        document.removeEventListener('selectstart', preventDefault);
      };

      const dragging = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        // Detectar si es evento touch o mouse
        const x = (e.pageX || e.touches[0].pageX) - carousel.offsetLeft;
        const walk = (x - startX) * 2;
        carousel.scrollLeft = scrollLeft - walk;

        if (carousel.scrollLeft >= imageBrands.scrollWidth / 2) {
          carousel.scrollLeft = 0;
        }
      };

      // Eventos para ratón
      carousel.addEventListener('mousedown', startDragging);
      carousel.addEventListener('mouseleave', stopDragging);
      carousel.addEventListener('mouseup', stopDragging);
      carousel.addEventListener('mousemove', dragging);

      // Eventos para pantallas táctiles
      carousel.addEventListener('touchstart', startDragging);
      carousel.addEventListener('touchend', stopDragging);
      carousel.addEventListener('touchmove', dragging);

      startAutoScroll();
      carousel.style.cursor = 'grab';
    },
    beforeDestroy() {
      clearInterval(this.autoScroll);
    }
  }
};
</script>

<style scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.carousel-outer-wrapper {
  background-color: white; /* Fondo blanco del contenedor exterior */
  padding: 40px 0; /* Espacio arriba y abajo */
}

.carousel-wrapper-brands {
  overflow: hidden;
  width: 100%;
}

.carousel-brands {
  display: flex;
  width: 100%;
  overflow: hidden; /* Ocultar la barra de desplazamiento */
  position: relative;
  user-select: none;
}

.image__brands {
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
}

.image__brands img {
  width: 150px;
  height: auto;
  margin-right: 120px;
  object-fit: contain;
  transition: transform 0.3s ease, opacity 0.8s ease;
  filter: grayscale(100%) brightness(180%);
  user-select: none;
  z-index: 1;
}

.image__brands img:hover {
  transform: scale(1.2);
  opacity: 0.8;
  filter: grayscale(100%) brightness(120%);
  position: relative;
  z-index: 2; /* Asegura que la imagen en hover esté por encima del resto */
}
</style>
