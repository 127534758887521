import Vue from 'vue';
import Router from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import AboutPage from '@/views/AboutPage.vue';
import ContactPage from '@/views/ContactPage.vue';
import ProductPage from '@/views/ProductPage.vue';
import TermsAndConditionsPage from '@/views/TermsAndConditionsPage.vue';
import BrandProductsPage from '@/views/BrandProductsPage.vue'; // Importa el componente que muestra productos de una marca

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage
    },
    {
      path: '/contact',
      name: 'ContactPage',
      component: ContactPage
    },
    {
      path: '/product',
      name: 'ProductPage',
      component: ProductPage
    },
    {
      path: '/terms',
      name: 'TermsAndConditions',
      component: TermsAndConditionsPage
    },
    {
      path: '/products/:brand', // Ruta dinámica para mostrar productos de una marca específica
      name: 'BrandProductsPage',
      component: BrandProductsPage
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Volver a la posición guardada cuando se navega hacia atrás
    } else {
      return { x: 0, y: 0 }; // Desplazar hacia la parte superior (inicio de la página)
    }
  }
});
