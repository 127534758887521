<template>
  <!-- Only render the top-bar in desktop mode -->
  <div class="top-bar" v-if="isDesktop && isVisible">
    <div class="top-bar-content">
      <!-- Información de contacto -->
      <div class="contact-info">
        <!-- WhatsApp y otros -->
        <a href="https://wa.me/51947229595" target="_blank" class="contact-item">
          <img src="../assets/images/social_medias/23-whatsapp-2-512.png" alt="WhatsApp" class="icon">
        </a>
        <p>LLAMA A NUESTROS ASESORES DE VENTAS</p>
        <span class="divider">|</span>

        <!-- Email -->
        <a href="mailto:ventas@irsadperu.com" class="contact-item">
          <img src="../assets/images/social_medias/3690706.png" alt="Email" class="icon">
        </a>
        <p>ventas@irsadperu.com</p>
        <span class="divider">|</span>

        <!-- Imagen del mapa -->
        <a @click.prevent="handleMapClick" class="contact-item">
          <img src="../assets/images/social_medias/maps-icon-16.png" alt="Visítanos" class="icon">
        </a>
        <p>VISÍTANOS</p>
      </div>
      <!-- Redes sociales -->
      <div class="social-icons">
        <a href="https://www.facebook.com/irsadperu?locale=es_LA" target="_blank">
          <img src="../assets/images/social_medias/fb.png" alt="Facebook" class="social-icon">
        </a>
        <a href="https://www.instagram.com" target="_blank">
          <img src="../assets/images/social_medias/insta.png" alt="Instagram" class="social-icon">
        </a>
        <a href="https://www.youtube.com" target="_blank">
          <img src="../assets/images/social_medias/yt.jpg" alt="YouTube" class="social-icon">
        </a>
        <a href="https://www.twitter.com" target="_blank">
          <img src="../assets/images/social_medias/twitter.png" alt="Twitter" class="social-icon">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBar',
  data() {
    return {
      isVisible: true, // To track top-bar visibility
      isDesktop: window.innerWidth > 768, // To track if it's a desktop or mobile
    };
  },
  mounted() {
    // Handle scroll for showing/hiding top-bar on larger screens
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize); // Handle window resize
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize); // Clean up resize listener
  },
  methods: {
    handleScroll() {
      if (this.isDesktop) {
        // Only handle top-bar visibility in desktop mode
        this.isVisible = window.scrollY <= 50;
      }
    },
    handleResize() {
      // Update whether it's desktop or mobile on window resize
      this.isDesktop = window.innerWidth > 768;
      // If we switch to mobile, ensure top-bar doesn't reappear
      if (!this.isDesktop) {
        this.isVisible = false; // Ensure top-bar stays hidden on mobile
      }
    },
    handleMapClick() {
      if (this.$route.name === 'HomePage') {
        // Si estamos en la HomePage, desplazamos al final de la página
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
      } else {
        // Si estamos en otra página, redirigimos a la HomePage y desplazamos al final
        this.$router.push({ name: 'HomePage' }).then(() => {
          // Desplazar al final después de la redirección
          setTimeout(() => {
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: 'smooth',
            });
          }, 500); // Pequeña espera para asegurarnos de que la página se ha cargado
        });
      }
    },
  },
};
</script>

<style scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.top-bar {
  width: 100%;
  background-color: black;
  color: white;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 101;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.top-bar-content {
  display: flex;
  justify-content: center;
  width: 90%;
  align-items: center;
}

.contact-info {
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
}

.contact-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  margin-right: 15px;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 2px;
  filter: brightness(0) saturate(100%) invert(12%) sepia(90%) saturate(7499%) hue-rotate(353deg) brightness(91%) contrast(109%);
  transition: filter 0.3s ease;
  cursor: pointer; /* Asegura que el cursor cambie a "puntero" */
}

.icon:hover {
  filter: brightness(0) invert(1);
}

.divider {
  color: white;
  margin: 0 40px;
}

.social-icons a {
  margin-left: 30px;
}

.social-icon {
  width: 17px;
  height: 17px;
  filter: brightness(0) saturate(100%) invert(12%) sepia(90%) saturate(7499%) hue-rotate(353deg) brightness(91%) contrast(109%);
  transition: filter 0.3s ease;
}

.social-icon:hover {
  filter: brightness(0) invert(1);
}

@media (max-width: 768px) {
  .top-bar {
    display: none !important; /* Ensure top-bar is fully removed in mobile view */
    visibility: hidden;
  }
}
</style>
