<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="footer__section">
        <h4>Contacto</h4>
        <p>Av. Nicolas Arriola 1894, San Luis, Lima</p>
        <p>Teléfono: (+51) 998 307 576</p>
        <p>Av. Separadora Industrial 2960, Ate, Lima</p>
        <p>Teléfono: (+51) 923 526 740</p>
        <p>Av. Calle Alberto Guillen 720, Arequipa, Arequipa</p>
        <p>Teléfono: (+51) 939 187 067</p>
        <p>Calle San Agustin Km. 9 Lote 11, Asoc. Santa Maria, Cerro Colorado, Arequipa</p>
        <p>Teléfono: (+51) 953 002 951</p>
        <p>Teléfono: (01) 325-5548</p>
        <p>Email: ventas@irsadperu.com</p>
      </div>
      <div class="footer__section">
        <h4>Síguenos</h4>
        <a href="https://www.facebook.com/irsadperu?locale=es_LA"><img src="../assets/images/social_medias/fb.png" alt="Facebook"></a>
        <a href="#"><img src="../assets/images/social_medias/insta.png" alt="Instagram"></a>
        <a href="#"><img src="../assets/images/social_medias/yt.jpg" alt="YouTube"></a>
        <a href="#"><img src="../assets/images/social_medias/twitter.png" alt="Twitter"></a>
      </div>
      <div class="footer__section">
        <h4>Información</h4>
        <router-link to="/about">Sobre Nosotros</router-link><br>
        <router-link to="/product">Productos</router-link><br>
        <router-link to="/terms">Términos y Condiciones</router-link>
      </div>
      <div class="footer__section">
        <h4>Horario de Atención</h4>
        <p>Lun – Vie : 09:00 am a 06:00 pm</p>
        <p>Sábados: 09:00 am a 03:00 pm</p>
        <h4>Horario de cierre para envío a provincias</h4>
        <p>Lunes a viernes: 05:00 pm</p>
        <p>Sábados: 02:00 pm</p>
      </div>
    </div>
    <div class="footer__bottom">
      <p>&copy; 2024 Irsad. Todos los derechos reservados.</p>
    </div>
    <a href="https://wa.me/51947229595" class="whatsapp-button" target="_blank" rel="noopener noreferrer">
      <img src="/assets/images/buttons/png-clipart-social-media-computer-icons-whatsapp-button-social-media-text-trademark.png" alt="WhatsApp">
    </a>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  selectOption(option) {
      this.selectedOption = option; // Cambia la opción seleccionada
      this.menuActive = false; // Cierra el menú después de seleccionar una opción
    },
}
</script>

<style scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

footer {
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 20px 20px;
  text-align: center;
  position: relative;
  margin-bottom: 0;
}

.footer__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.footer__section {
  margin: 20px 0;
}

.footer__section h4 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #e41b23;
}

.footer__section p,
.footer__section a,
.footer__section router-link {
  color: #fff;
  margin: 5px 0;
  font-size: 14px;
  text-decoration: none;
}

.footer__section a:hover,
.footer__section router-link:hover {
  color: #e41b23;
}

.footer__bottom {
  margin-top: 20px;
  font-size: 12px;
}

.footer__section img {
  width: 24px;
  margin: 0 10px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.footer__section img:hover {
  transform: scale(1.2);
  opacity: 0.8;
}

/* Responsive Footer */
@media (min-width: 768px) {
  .footer__content {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    text-align: left;
  }

  .footer__section {
    margin: 0 20px;
  }
}

/* WhatsApp Button Styling */
.whatsapp-button {
  position: fixed;
  bottom: 80px;
  right: 10px;
  width: 60px;
  height: 60px;
  z-index: 1000;
}

.whatsapp-button img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.whatsapp-button img:hover {
  transform: scale(1.2);
  opacity: 0.8;
}

@media (max-width: 1024px) {
  .whatsapp-button {
    width: 50px;
    height: 50px;
    bottom: 85px;
    right: 5px;
  }
}
</style>