<template>
  <div class="terms">
    <section class="terms__title">
        <div class="terms__title-image">
          <img src="@/assets/images/cover/red-and-black-abstract-elegant-geometric-background-hd-seenliirgq.jpg" alt="">
        </div>
        <div class="terms__title-text">
          <h1>Términos y Condiciones</h1>
        </div>
      </section>
    <div class="terms-page">      
        
      <section>
        <h2>1. Introducción</h2>
        <p>
          Bienvenido a IRSAD Perú. Estos términos y condiciones ("Términos") regulan el uso de nuestro sitio web ubicado en "irsadperu.com". Al acceder y utilizar nuestro Sitio, aceptas estar sujeto a estos Términos, así como a nuestra política de privacidad. Si no estás de acuerdo con estos Términos, no deberías utilizar nuestro Sitio.
        </p>
      </section>
  
      <section>
        <h2>2. Uso del Sitio</h2>
        <h3>2.1 Acceso</h3>
        <p>
          El acceso a nuestro Sitio está permitido de forma temporal, y nos reservamos el derecho de retirar o modificar el servicio que ofrecemos en nuestro Sitio sin previo aviso.
        </p>
        <h3>2.2 Conducta del usuario</h3>
        <p>
          Al utilizar nuestro Sitio, te comprometes a no:
          <ul>
            <li>Usar nuestro Sitio de manera que cause, o pueda causar, que se interrumpa, dañe o disminuya la eficiencia del Sitio.</li>
            <li>Participar en cualquier actividad que sea ilegal, fraudulenta o que pueda infringir los derechos de terceros.</li>
            <li>Intentar obtener acceso no autorizado a nuestro Sitio o a los servidores donde está alojado nuestro Sitio.</li>
          </ul>
        </p>
        <h3>2.3 Disponibilidad</h3>
        <p>
          Hacemos todo lo posible para que el Sitio esté disponible en todo momento, pero no podemos garantizar que esté libre de errores, virus o que el acceso será ininterrumpido.
        </p>
      </section>
  
      <section>
        <h2>3. Propiedad Intelectual</h2>
        <h3>3.1 Derechos</h3>
        <p>
          Todo el contenido, diseño gráfico, logotipos, imágenes, texto y software en este Sitio son propiedad de IRSAD Perú o sus licenciatarios y están protegidos por leyes de derechos de autor, marcas y otras leyes de propiedad intelectual.
        </p>
        <h3>3.2 Uso del contenido</h3>
        <p>
          No se te otorgan derechos sobre el contenido del Sitio y no puedes utilizar, copiar, reproducir, distribuir, o modificar el contenido sin nuestro consentimiento previo por escrito.
        </p>
      </section>
  
      <section>
        <h2>4. Enlaces a otros sitios web</h2>
        <p>
          Nuestro Sitio puede contener enlaces a sitios web de terceros. Estos enlaces se proporcionan únicamente para tu conveniencia. No controlamos, ni somos responsables, de los contenidos o la disponibilidad de esos sitios. El acceso a dichos sitios es bajo tu propio riesgo.
        </p>
      </section>
  
      <section>
        <h2>5. Limitación de responsabilidad</h2>
        <h3>5.1 Exclusión de garantías</h3>
        <p>
          El Sitio y su contenido se proporcionan "tal cual" y "según disponibilidad". No ofrecemos garantías de ningún tipo, ya sean expresas o implícitas, respecto a la operación del Sitio o la información, contenido o materiales incluidos en él.
        </p>
        <h3>5.2 Limitación</h3>
        <p>
          En la medida permitida por la ley, no seremos responsables por daños y perjuicios de cualquier naturaleza que puedan derivarse del uso del Sitio, incluyendo, pero no limitado a, daños directos, indirectos, incidentales, punitivos o consecuentes.
        </p>
      </section>
  
      <section>
        <h2>6. Modificaciones</h2>
        <p>
          Nos reservamos el derecho de revisar y modificar estos Términos en cualquier momento. Cualquier cambio será efectivo inmediatamente después de su publicación en el Sitio. Es tu responsabilidad revisar los Términos periódicamente para estar informado de cualquier cambio.
        </p>
      </section>
  
      <section>
        <h2>7. Ley aplicable</h2>
        <p>
          Estos Términos se regirán e interpretarán de acuerdo con las leyes del Perú. Cualquier disputa que surja en relación con estos Términos será sometida a la jurisdicción exclusiva de los tribunales del Perú.
        </p>
      </section>
  
      <section>
        <h2>8. Contacto</h2>
        <p>
          Si tienes alguna pregunta sobre estos Términos, puedes ponerte en contacto con nosotros a través del correo electrónico: ventas@irsadperu.com.
        </p>
      </section>
    </div>
  </div>
    
  </template>
  
  <script>
  export default {
    name: 'TermsAndConditionsPage',
  };
  </script>
  
  <style scoped>
  /* Estilos para la sección del título */
  .terms__title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: white; /* Fondo blanco */
  }
  
  .terms__title-image {
    width: 100vw; /* Asegura que la imagen cubra todo el ancho de la pantalla */
    height: 35vh; /* Ajusta la altura según sea necesario */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  .terms__title-image img {
    width: 100%; /* La imagen cubre todo el ancho */
    height: 100%; /* La imagen cubre toda la altura */
    object-fit: cover; /* Asegura que la imagen cubra el contenedor sin deformarse */
    display: block;
  }
  
  .terms__title-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    padding: 20px;
    z-index: 10; /* Asegura que el texto esté sobre la imagen */
    font-size: 30px;
  }
  
  /* Estilos para el contenido de la página de términos */

  .terms{
    background: black;
  }
  .terms-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: white; /* Fondo blanco */
    line-height: 1.6;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  h2, h3 {
    color: #48070bfa;
  }
  
  h2 {
    font-size: 24px;
    margin-top: 30px;
  }
  
  h3 {
    font-size: 18px;
    margin-top: 20px;
  }
  
  p, ul {
    margin-bottom: 15px;
  }
  
  ul {
    padding-left: 20px;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  </style>