<template>
  <div class="home">
    <main>
      <!-- Usa el componente del carrusel principal -->
      <CarouselMain :carouselImages="carouselImages" />

      <!-- Usa el componente del carrusel de marcas -->
      <CarouselBrands :brands="brands" />

      <!-- Cover Section -->
      <div class="container__cover div__offset">
        <div class="cover">
          <div class="cover__content">
            <!-- Texto y botón a la izquierda -->
            <div class="text__cover">
              <h1>Descarga de Factura Electrónica</h1>
              <p>Descarga tu factura electrónica de manera rápida y sencilla a través de nuestro sitio web.</p>
              <a href="https://sistematic.pse.pe/buscar" class="btn__text-cover btn__text" target="_blank" rel="noopener noreferrer">Click aquí</a>
            </div>

            <!-- Imagen con fondo blanco transparente a la derecha -->
            <div class="image__cover">
              <img src="@/assets/images/backgrounds/istockphoto-1388056729-612x612.jpg" alt="Factura Electrónica">
            </div>
          </div>
        </div>
      </div>

      <!-- Trust Section -->
      <div class="container__trust container__card-primary">
        <div class="trust card__primary">
          <div class="text__trust text__card-primary">
            <p>GENERA CONFIANZA</p>
            <h1>Encuentra los mejores repuestos</h1>
          </div>
          <div class="container__trust container__box-cardPrimary">
            <div class="card__trust box__card-primary" v-for="(trust, index) in trustItems" :key="index">
              <h2>{{ trust.title }}</h2>
              <img :src="trust.imgSrc" :alt="'Service ' + (index + 1)">
              <p>{{ trust.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- About Section -->
      <div id="about" class="about-container">
  <div class="about-content">
    <div class="header">
      <span class="badge">MUY PRONTO</span>
      <h1>Sitio en Construcción</h1>
    </div>
    <div class="icon">
      <img src="@/assets/images/icons/ZSj2.gif" alt="Icono de Advertencia">
    </div>
    <div class="progress-bar">
      <span>0%</span>
      <div class="bar">
        <div class="fill"></div>
      </div>
      <span>100%</span>
    </div>
    <div class="email-notify">
      <input type="email" placeholder="correo electrónico" />
      <button>NOTIFÍCAME</button>
    </div>
  </div>
</div>


      <!-- Service Section -->
      <div class="service-container container__card-primary div__offset">
        <div class="service card__primary">
          <div class="text__service text__card-primary">
            <p>ENCUÉNTRANOS</p>
            <h1>Encuéntranos en nuestros diferentes locales</h1>
          </div>
          <div class="container__card-service container__box-cardPrimary">
            <a v-for="(service, index) in services" :key="index" :href="service.mapLink">
              <div class="card__service box__card-primary">
                <h2>{{ service.title }}</h2>
                <img :src="service.imgSrc" alt="Store Logo">
                <p>{{ service.address }}</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import CarouselMain from '@/components/CarouselMain.vue';
import CarouselBrands from '@/components/CarouselBrands.vue';

import image1 from '@/assets/images/catalogo/Eatonw.png';
import image2 from '@/assets/images/catalogo/Dffferential-parts.png';
import image3 from '@/assets/images/catalogo/automann web2.jpg';
import image4 from '@/assets/images/catalogo/FP-WEB1.png';


import brand1 from '@/assets/images/marcas/automann.png';
import brand2 from '@/assets/images/marcas/DANA.png';
import brand3 from '@/assets/images/marcas/EATON.png';
import brand4 from '@/assets/images/marcas/FERSA.png';
import brand5 from '@/assets/images/marcas/kysor.png';
import brand6 from '@/assets/images/marcas/PAI.png';
import brand7 from '@/assets/images/marcas/SPICER.png';
import brand8 from '@/assets/images/marcas/DEVS_parts-removebg-preview.png';
import brand9 from '@/assets/images/marcas/WhatsApp_Image_2024-10-10_at_13.30.59_84b2629d-removebg-preview.png';
import brand10 from '@/assets/images/marcas/fuller.png';
import brand11 from '@/assets/images/marcas/merito.png'; 

import services1 from '@/assets/images/icons/box.png';
import services2 from '@/assets/images/icons/give-money.png';
import services3 from '@/assets/images/icons/training.png';

export default {
  name: 'HomePage',
  components: {
    CarouselMain,
    CarouselBrands
  },
  data() {
    return {
      carouselImages: [
      {
          smallImage: brand10,
          skus: ['Partes para transmisiones y diferenciales. En marcas OEM y alternativas, cobertura completa para tu tractocamión.'],
          image: image1
        },
        {
          smallImage: brand9,
          skus: ['Los componentes y kits de FP Diesel® están diseñados por un equipo de ingenieros dedicados al mercado de repuestos para cumplir con las especificaciones más exigentes.'],
          image: image4
        },
        {
          smallImage: brand11,
          skus: ['Partes para transmisiones y diferenciales. En marcas OEM y alternativas, cobertura completa para tu tractocamión.'],
          image: image2
        },
        {
          smallImage: brand1,
          skus: ['Distribuidor mundial de partes y componentes para camiones y tractocamiones.'],
          image: image3
        },
        {
          smallImage: brand11,
          skus: ['Partes para transmisiones y diferenciales. En marcas OEM y alternativas, cobertura completa para tu tractocamión.'],
          image: image2
        },
        {
          smallImage: brand9,
          skus: ['Los componentes y kits de FP Diesel® están diseñados por un equipo de ingenieros dedicados al mercado de repuestos para cumplir con las especificaciones más exigentes.'],
          image: image4
        },
        // Agrega más imágenes según necesites
      ],
      brands: [brand1, brand2, brand3, brand4, brand5, brand6, brand7, brand8],
      trustItems: [
        {
          imgSrc: services1,
          title: 'Entrega',
          description: 'Nos encargamos de que tus pedidos lleguen a tiempo y de manera segura, asegurando un servicio de entrega eficiente y confiable.'
        },
        {
          imgSrc: services2,
          title: 'Medios de Pago',
          description: 'Ofrecemos diversas opciones de pago, adaptadas a tus necesidades, para que puedas realizar tus compras de manera fácil y segura.'
        },
        {
          imgSrc: services3,
          title: 'Asesoría',
          description: 'Ofrecemos asesoría especializada para que nuestros clientes conozcan el correcto uso y mantenimiento de las autopartes.'
        }
      ],
      services: [
        {
          mapLink: 'https://maps.app.goo.gl/6ATSo2Ct76wZCJRx9',
          imgSrc: require('@/assets/images/social_medias/logo.png'),
          title: 'Tienda San Luis',
          address: 'Av. Nicolas Arriola 1894 San Luis 15021, Lima'
        },
        {
          mapLink: 'https://maps.app.goo.gl/5HvzDfFvygxq6Wog7',
          imgSrc: require('@/assets/images/social_medias/logo.png'),
          title: 'Tienda Ate',
          address: 'Av. Separadora Industrial 2960 Ate 15012, Lima'
        },
        {
          mapLink: 'https://maps.app.goo.gl/yEq8x25VUdhd3MvD7',
          imgSrc: require('@/assets/images/social_medias/logo.png'),
          title: 'Tienda Arequipa',
          address: 'Calle Alberto Guillen 720 Arequipa 04002, Arequipa'
        },
        {
          mapLink: 'https://maps.app.goo.gl/WrWFoeFHhpJRgQZa7',
          imgSrc: require('@/assets/images/social_medias/logo.png'),
          title: 'Tienda Arequipa - Cono Norte',
          address: 'Calle San Agustin Km. 9 Lote 11 - Asoc. Santa Maria, Cerro Colorado, Arequipa'
        }
      ]
    };
  },
  mounted() {
    // Escuchar el evento emitido desde AppHeader
    this.$root.$on('scrollToAbout', this.scrollToAboutSection);
  },
  beforeDestroy() {
    // Eliminar el listener cuando el componente sea destruido
    this.$root.$off('scrollToAbout', this.scrollToAboutSection);
  },
  methods: {
    // Método para hacer scroll a la sección "About"
    scrollToAboutSection() {
      const aboutSection = document.querySelector('#about');
      if (aboutSection) {
        aboutSection.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }
};
</script>

<style scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

/* Estilos del Cover Section */
.container__cover {
  padding: 100px 0px;
  background-color: black;
  min-height: 78vh; /* Cambia height a min-height para que crezca si es necesario */
  width: 100%; /* Asegura que ocupe el ancho completo */
  overflow-x: hidden; /* Previene desbordamientos horizontales */
  overflow-y: hidden; /* Evita desbordamientos verticales */
}

.cover {
  display: flex;
  justify-content: center; /* Alinea horizontalmente el contenido */
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  padding: 0 20px;
}

/* Contenedor que agrupa el texto y la imagen */
.cover__content {
  display: flex;
  justify-content: space-between; /* Distribuye el texto a la izquierda y la imagen a la derecha */
  align-items: center; /* Centra verticalmente el contenido */
  width: 100%;
  max-width: 1200px; /* Limita el ancho máximo de la sección para mejor ajuste */
  margin: 0 auto; /* Centra el contenido dentro de la página */
  padding: 20px; /* Ajusta el padding para reducir espacio blanco */
  min-height: 80vh; /* Asegura que el contenedor tenga una altura mínima */
}

/* Texto y botón a la izquierda */
.text__cover {
  width: 45%; /* Ocupa el 45% del ancho total */
  text-align: left; /* Alinea el texto a la izquierda */
}

.text__cover h1 {
  color: white;
  font-weight: 600;
  font-size: 3rem;
}

.text__cover p {
  font-size: 1.5rem;
  margin-top: 20px;
  color: white;
}

.btn__text-cover {
  display: block;
  width: 150px;
  margin-top: 30px;
  padding: 10px 20px;
  text-align: center;
  font-weight: 500;
  background-color: transparent;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  border: 2px solid white;
  cursor: pointer;
  transition: all 700ms;
  letter-spacing: 3px;
}

.btn__text-cover:hover {
  background: white;
  border: 2px solid white;
  color: black;
}

/* Imagen con fondo blanco transparente a la derecha */
.image__cover {
  background-color: transparent; /* Fondo blanco con 50% de opacidad */
  padding: 20px;
  border-radius: 10px;
  display: inline-block; /* Ajusta el contenedor al tamaño de la imagen */
  width: 55%; /* Ajusta el ancho de la imagen a 45% */
}

.image__cover img {
  width: 100%; /* La imagen ocupa el 100% del contenedor */
  height: auto;
  display: block;
  margin: 0 auto; /* Centra la imagen dentro del contenedor */
}

/* Responsivo */
@media (max-width: 768px) {
  .cover__content {
    flex-direction: column; /* Coloca el texto y la imagen en una columna para móviles */
    text-align: center;
    padding: 20px;
    min-height: auto; /* Elimina la altura mínima en pantallas móviles */
  }

  .text__cover, .image__cover {
    width: 100%; /* El contenido ocupa todo el ancho en móviles */
  }

  .image__cover {
    margin-top: 20px; /* Añade un espacio entre el texto y la imagen */
  }
}

/* Estilos del Trust Section */
.container__trust {
  padding: 30px 0;
  width: 100%;
  overflow-x: hidden;
}

.text__trust {
  text-align: center;
  margin-bottom: 40px;
}

.text__trust p {
  color: #e41b23;
  letter-spacing: 4px;
  font-size: 16px;
  font-weight: 500;
}

.text__trust h1 {
  font-size: 40px;
  font-weight: 600;
  color: #48070bfa;
  margin-top: 10px;
}

.container__box-cardPrimary {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 1200px; /* Asegura que el contenedor no sea demasiado ancho */
  margin: 0 auto; /* Centra el contenedor */
}

.box__card-primary {
  width: 300px;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  padding: 20px;
  margin: 0;
  transition: all 300ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.box__card-primary:hover {
  border: 1px solid transparent;
  box-shadow: 0px 6px 50px -20px rgba(0, 0, 0, 0.2);
}

.box__card-primary h2 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #e41b23;
  margin-top: 0;
}

.box__card-primary p {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  margin: 0;
}

.container__card-primary img {
  width: 25%;
  margin: 10px 0;
  display: block;
}

/* Estilos del About Section */
.about-container {
  background-image: url('/src/assets/images/backgrounds/1704558764097.png');
  background-size: cover;
  background-position: center;
  padding: 80px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-content {
  background-color: rgba(255, 255, 255, 0.8); /* Fondo blanco con opacidad */
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  max-width: 800px;
}

.header .badge {
  background-color: black;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
}

.header h1 {
  color: black;
  font-size: 2.5rem;
  margin-top: 20px;
  font-weight: bold;
}

.icon {
  margin: 30px 0;
}

.icon img {
  width: 80px;
}

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.progress-bar .bar {
  width: 80%;
  background-color: #e0e0e0;
  height: 10px;
  border-radius: 5px;
  position: relative;
}

.progress-bar .bar .fill {
  background-color: black;
  height: 100%;
  width: 40%; /* Porcentaje de progreso, ajústalo según sea necesario */
  border-radius: 5px;
}

.email-notify {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.email-notify input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  width: 70%;
  font-size: 1rem;
}

.email-notify button {
  padding: 10px;
  background-color: #e41b23;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
}

.email-notify button:hover {
  background: white;
  border: 2px solid #bb0000;
  color: black;
}

/* Aquí están los ajustes solicitados para la sección de servicios */

.service-container {
  background-color: white; /* Fondo blanco para todo el contenedor de servicios */
  width: 100%; /* Asegura que ocupe todo el ancho */
  margin: 0 auto; /* Centra el contenedor */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; /* Centra el texto */
  height: auto;
  padding-top: 70px; /* Añade espacio arriba */
  padding-bottom: 70px; /* Añade más espacio abajo */
}


.container__service {
  padding: 50px 0; /* Ajusta el padding para más espacio */
  width: 100%;
  overflow-x: hidden;
}

.text__service {
  text-align: center; /* Centra el texto dentro del contenedor */
  margin-bottom: 40px; /* Espacio abajo del texto */
}

.text__service p {
  color: #e41b23;
  letter-spacing: 4px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 60px;
}

.text__service h1 {
  font-size: 40px;
  font-weight: 600;
  color: #48070bfa;
  margin-top: 20px;
  margin-bottom: 40px; /* Aumenta el espacio abajo del título */
}

.container__card-service {
  display: flex;
  justify-content: space-between; /* Distribuye uniformemente las tarjetas con espacio entre ellas */
  align-items: stretch; /* Asegura que todas las tarjetas tengan la misma altura */
  flex-wrap: wrap; /* Permite que las tarjetas se muevan a la siguiente fila si es necesario */
  gap: 20px; /* Añade espacio entre las tarjetas */
}

.card__service {
  flex: 1; /* Asegura que todas las tarjetas crezcan igual para ocupar el mismo espacio */
  max-width: 250px; /* Establece un ancho máximo para las tarjetas */
  padding: 20px;
  text-align: center;
  background-color: white;
  border-radius: 8px;
  box-sizing: border-box;
  height: 100%; /* Asegura que las tarjetas tengan la misma altura */
  margin-top: 20px;
  display: flex;
  flex-direction: column; /* Asegura que el contenido esté bien alineado dentro de las tarjetas */
  justify-content: space-between; /* Asegura que el contenido interno se distribuya de manera uniforme */
}

.card__service h2, .card__service p {
  text-align: center; /* Asegura que el texto esté centrado dentro de las tarjetas */
}

.container__card-primary img {
  width: 100px;
  margin: 10px 0;
  display: block;
  margin-left: auto;
  margin-right: auto; /* Centra la imagen */
}

@media (max-width: 768px) {
  .about-container {
    padding: 50px 20px;
  }

  .container__card-service {
    justify-content: center; /* Asegura que las tarjetas estén centradas en móviles */
    flex-direction: column; /* Alinea las tarjetas una debajo de otra en móviles */
  }

  .card__service {
    width: 100%; /* Asegura que la tarjeta ocupe todo el ancho en pantallas pequeñas */
    max-width: none; /* Permite que el ancho sea el 100% */
  }
}
</style>