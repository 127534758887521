<template>
  <div class="brand-product-page">
    <section class="brand-header">
      <div class="brand-image">
        <img :src="localImage" :alt="`Imagen de ${localBrand}`" class="slide-in-left">
      </div>
      <div class="brand-description">
        <div class="text-box slide-in-right">
          <h1>{{ localBrand }}</h1>
          <p>{{ localDescription }}</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'BrandProductsPage',
  props: {
    brand: String,
    image: String,
    description: String
  },
  data() {
    return {
      localBrand: '',
      localImage: '',
      localDescription: ''
    };
  },
  created() {
    this.localBrand = this.$route.params.brand;
    this.localImage = this.$route.params.image;
    this.localDescription = this.$route.params.description;
  }
};
</script>

<style scoped>
.brand-product-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  background: white;
  overflow: hidden;
}

.brand-header {
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  gap: 20px;
}

.brand-image {
  flex: 1;
  width: 50%;
}

.brand-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: scale-down;
  animation: slideInLeft 1s ease forwards;
  opacity: 0;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.brand-description {
  flex: 1;
  width: 50%;
  padding: 20px;
  display: flex;
  align-items: center;
}

.text-box {
  background-color: whitesmoke;
  padding: 20px;
  border-radius: 8px;
  animation: slideInRight 1s ease forwards;
  opacity: 0;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.brand-description h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.brand-description p {
  font-size: 1.2em;
  color: #555;
  text-align: justify;
}

/* Estilos responsive para móviles */
@media (max-width: 768px) {
  .brand-header {
    flex-direction: column;
    text-align: center;
  }

  .brand-image,
  .brand-description {
    width: 100%;
  }

  .brand-description h1 {
    font-size: 1.5em;
  }

  .brand-description p {
    font-size: 1em;
    text-align: justify
  }

  .text-box {
    padding: 15px;
  }
}
</style>
