<template>
  <div class="product-card" @click="$emit('click')">
    <img :src="product.image" alt="product.name" class="product-image" />
    <h3>{{ product.name }}</h3>
  </div>
</template>

<script>
export default {
  props: {
    product: Object
  },
  methods: {
    viewDetails() {
      this.$emit('view-details', this.product);
    }
  }
};
</script>

<style scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.product-card {
  border: 1px solid #ddd;
  text-align: center;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px; /* Ajusta la altura de la tarjeta para dar más espacio a las imágenes */
  cursor: pointer;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-image {
  width: 100%;
  height: auto; /* Mantiene la proporción de la imagen */
  max-height: 150px; /* Limita la altura máxima sin recortar la imagen */
  object-fit: contain; /* Asegura que la imagen no se recorte ni se deforme */
  margin-bottom: 10px;
}

.details-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #e10000;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.details-button:hover {
  background-color: #c10000;
}
</style>
